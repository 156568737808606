<template>
    <Cart />
</template>

<script>
import Cart from '@apps/Orders/components/Cart/ReturnCart.vue'
export default {
    components: {
        Cart
    }
}
</script>